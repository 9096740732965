
//==========  List styles  ==========//

// list item styles
%default-list {
    padding: 0 0 0 $block-padding;
    margin: 0 0 $paragraph-margin;
}

%default-ul {
    list-style: disc;
    @extend %default-list;
}

%default-ol {
    list-style: decimal;
    @extend %default-list;
}

// list item reset
%reset-ul {
    list-style: none;
    padding: 0;
    margin: 0;
}


//==========  Buttons and default styles  ==========//

// base button styles
// extend for different button styles
%button {
    display: inline-block;
    position: relative;
    border: none;
    color: $color-text;
    padding-bottom: $site-padding / 2;
    text-align: center;
    transition: $transition;
    font-family: $font-family-title;
    font-size: $h6;
    font-weight: $font-medium;
    text-transform: capitalize;

    @media #{$tabletXL} {
       font-size: $h5;
    }

    &:after {
        content: "";
        position: absolute;
        left: 0;
        bottom: 0;
        width: 30px;
        height: 5px;
        background: $color-secondary;
        transition: $transition;
        z-index: z(front) + 1;
    }
}

%hover {
    color: $color-text;

    &:after {
        width: 75%;
    }
}

// extend for default .read-more
%read-more {
    @extend %button;

    &:hover {
        @extend %hover;
    }
}

