
//==========  Animations  ==========//

// define custom animations here
// make sure to use .mdzrno-cssanimations for fallbacks where there is no support
// IE10+, webkit, moz

// form animations on error
@keyframes hippyshake {
    0% { transform: translateX(2px) }
    20% { transform: translateX(0) }
    40% { transform: translateX(-2px) }
    60% { transform: translateX(0) }
    80% { transform: translateX(2px) }
    100% { transform: translateX(0) }
}


// define loading animation
@keyframes loading {

	50% {
		transform: scale(1);
		opacity: 1;
	}

	100% {
		opacity: 0;
	}

}

// animate trigger class
.animate {
	opacity: 0;
	animation-duration: 0.5s;
	animation-fill-mode: both;
	animation-timing-function: cubic-bezier(0.2, 0.3, 0.25, 0.9);
}

// animation keyframes
@keyframes fadeIn {
    // 0% {
    //     opacity: 0;
	// }
    100% {
        opacity: 1;
    }
}

.fade-in {
    animation-name: fadeIn;
}

@keyframes fadeInUp {
    0% {
        // opacity: 0;
        transform: translate3d(0, 20px, 0);
    }
    100% {
        opacity: 1;
        transform: none;
    }
}

.fade-in-up {
    animation-name: fadeInUp;
}

@keyframes fadeInLeft {
    0% {
        // opacity: 0;
        transform: translate3d(20px, 0, 0);
    }
    100% {
        opacity: 1;
        transform: none;
    }
}

.fade-in-left {
    animation-name: fadeInLeft;
}

// delay helpers
.delay-500 {
  animation-delay: 0.5s;
}

.delay-1000 {
  animation-delay: 1s;
}

.delay-1000 {
  animation-delay: 1s;
}

.delay-1200 {
  animation-delay: 1.2s;
}
