
// Content styles
.block-text,
.biography {

	// Default paragraphs
	p {
		margin: 0 0 $paragraph-margin;
		line-height: 1.6;

		@media #{$tablet} {
		   line-height: 1.7;
		}

		&.intro {
			border-left: $border-size-accent $border-style $color-secondary;
			color: lighten($color-darkgrey-light, 10%);
			font-size: $h6;
			font-weight: $font-light;
			line-height: 1.5;
			margin: 0 0 $block-margin;
			padding: ($site-padding / 3) 0 ($site-padding / 3) calc(#{$site-padding} + #{$border-size-accent});

			@media #{$laptop} {
				font-size: $h5;
				margin: ($site-padding * 1.5) 0 ($block-margin * 1.25);
			}

			@media #{$desktop} {
			   font-size: $h5 + .2;
			}
		}
	}

	// reset any <ul> that will appear in a template here
	// maintain vertical rhythm with paragraph margin
	ul[class] {
		@extend %reset-ul;
		margin: 0 0 $paragraph-margin;
	}

	// New default <ul> lists
	ul {
		@extend %default-ul;
	}

	// New default <ol> lists
	ol {
		@extend %default-ol;
	}
}

