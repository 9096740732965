@include font-face($font-family);

// Headings
h1, h2, h3, h4, h5, h6,
.h1,.h2,.h3,.h4,.h5,.h6 {
	margin: 0 0 $heading-margin;
	color: $color-title;
	font-family: $font-family-title;
	font-weight: $font-medium;
	line-height: 1.1;
}

h1,
.h1 {
	font-size: $h1 * 0.5;
	margin: 0 0 ($heading-margin * 2);

	@media #{$tabletXL} {
	   font-size: $h1;
	}
}

h2,
.h2 {
	font-size: $h2 * 0.5;

	@media #{$tabletXL} {
	   font-size: $h2;
	}
}

h3,
.h3 {
	font-size: $h3 * 0.5;

	@media #{$tabletXL} {
	   font-size: $h3;
	}
}

h4,
.h4 {
	font-size: $h4 * 0.5;

	@media #{$tabletXL} {
	   font-size: $h4;
	}
}

h5,
.h5 {
	font-size: $h5 * 0.5;

	@media #{$tabletXL} {
	   font-size: $h5;
	}
}

// h6,
// .h6 {
// 	font-size: $h6 - 0.2;

// 	@media #{$tabletXL} {
// 	   font-size: $h6;
// 	}
// }

