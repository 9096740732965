
//==========  Fonts  ==========//

$font-family: Butler; // the family name of our font - should match filename

// the font weights we're using - should match filename
$fonts: (
	Medium 	: 500
);

// call our font families and fallbacks
// variables for title, body and sub paragraphs are called in /core/_typography.scss

$font-family-title: $font-family, 'Georgia', serif;	                        // for heading tags
$font-family-body:  'Palanquin', 'Helvetica Neue', 'Arial', sans-serif;		// for body text
$font-family-sub:  $font-family-title;				                        // for sub and intro text


//==========  Font weights  ==========//

// normal and bold are used in our modules as defaults
// set your most commonly used weights to use these if possible

$font-light: 		300;
$font-normal: 		400;	// Normal 400
$font-medium:   	500;
$font-bold: 		600;	// Bold 600


//==========  Font styles  ==========//

// set font-style in variable so its easy to update
$font-italic: italic;


//==========  Font sizes  ==========//

// set sizes of heading tags
// we can then call these on any element to duplicate the tag

$h1: 5rem;
$h2: 3.75rem;
$h3: 2.5rem;
$h4: 2rem;
$h5: 1.75rem;
$h6: 1.2rem;

// set the default body font size
$font-size-body: 1.2rem;


//==========  Font margins  ==========//

// bottom margin for headings and paragraphs
// use to control the flow of content on the page
// lists in content will use our paragraph variable

$heading-margin: 1rem;
$paragraph-margin: 2rem;


